.bg-pricing {
    background-image: url(/assets/pricing/pricing.jpg);
    height: 50vh;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;


    h3 {
        margin-top: 7vh;
        font-size: 5em;
    }
}

//form

.form-benat {
    min-height: 80vh;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-top: -15vh;
    background-color: white;
    border-radius: 5px;
    padding: 50px;
    margin-bottom: 10vh;
    color: #089049;

    ::placeholder{
        color: #089049;
        font-weight: bold;
    }
    option{
        color:#089049;
        font-weight: bold;
    }
    select{
        color:#089049;
        font-weight: bold;
    }
    .form-control:focus {
        outline: none !important;
        border:1px solid #089049;
        box-shadow: 0 0 10px #089049;
      }
}
.btn-pricing{
    background-color: #089049;
    min-width: 10vw;
    margin-top: 2vh;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    
    &:hover{
        background-color: white;
        border: 2px solid #089049;
        color: #089049;

    }


}

/// radio

@media only screen and (max-width: 768px){
    .bg-pricing{
        height: 30vh;
       background-size: 100% 100%;

        h3{
            font-size: 3rem;
            margin-top: 3vh;
            text-align: right;
        }
    }
    .form-benat {
        width: 90vw;
    }

}





